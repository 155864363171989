import React, { useEffect, useState } from 'react'
import customer from '../../../assests/images/customerCard1.png'
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";
const Index = ({ id, image, headingText, descriptionText }) => {
    const [showData, setShowData] = useState(false)
    const showHiddenData = (e, myId) => {
        if (myId == id) {
            setShowData(!showData)
        }
    }
    return (
        <>
            <div className='position-relative overflow-hidden' >
                <div
                    className='backgroundOverlay p-4 d-flex justify-content-start '
                    style={{
                        flexDirection: "row",
                        backgroundImage: `url(${image})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '500px',
                    }}>

                    <div className='align-self-end  position-relative d-flex justify-content-between align-items-center w-100' style={{
                        opacity: showData ? 0 : 1,
                        transition: 'opacity 1s ease-in-out',
                    }}>
                        <h5 className='fs-4 fw-bold text-color-white m-0 w-inherit' >{headingText}</h5>
                        <AiOutlinePlusCircle fontSize={'55px'} className='text-color-white' style={{ cursor: 'pointer' }} onClick={(e) => showHiddenData(e, id)} />
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: '#204a547a',
                        clipPath: 'polygon(0 0, 100% 20%, 100% 100%, 0 100%, 0 0)',
                        minHeight: "250px",
                        position: 'absolute',
                        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
                        transform: `translateY(${showData ? '0' : '100%'})`,
                        opacity: showData ? 1 : 0,
                        bottom: 0,
                    }}
                    className='p-4 d-flex align-items-end'
                >
                    <div className=''>
                        <h5 className='fs-4 fw-bold text-color-white mb-2'>{headingText}</h5>
                        <p className='text-color-white'>{descriptionText}</p>
                    </div>
                    <div className='position-absolute' style={{ right: '20px', top: '60px', }}>
                        <AiOutlineMinusCircle fontSize={'45px'} className='text-color-white' style={{ cursor: 'pointer' }} onClick={(e) => showHiddenData(e, id)} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index
