import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assests/css/common.scss';
import App from './App';
import Screens from '../src/screens/app'
import ErrorPage from './components/error-Page';
import RouterManager from './routes/RouterManger'
// const { Home, FAQ } = Screens
// const router = createBrowserRouter([
//   {
//     element: <App />,
//     errorElement: <ErrorPage />,
//     children: [
//       {
//         path: '/',
//         element: <Home />
//       },
//       {
//         path: '/faq',
//         element: <FAQ />
//       }
//     ]
//   }
// ])

const RootComponent = () => {
  return (
    <>
      <React.StrictMode>
        <RouterManager />
      </React.StrictMode>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

