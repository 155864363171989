import React, { useEffect, useRef } from "react";
import marker from "../../assests/svg/Vector.svg";
import placeholder from "../../assests/images/placeholderImages.png";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon, LatLngBounds } from "leaflet";
import "./styles.scss";
import { FiPhone } from "react-icons/fi";
const Index = ({ storeData }) => {
  const mapRef = useRef(null);
  const latitude = 22.719568;
  const longitude = 75.857727;
  const customIcon = new Icon({
    iconUrl: marker,
    iconSize: [30, 30],
  });
  
  useEffect(() => {
    if (storeData && storeData.length > 0 && mapRef.current) {
      const map = mapRef.current;
      const bounds = new LatLngBounds();
      storeData.forEach((store) => {
        if (store.latitude && store.longitude) {
          bounds.extend([store.latitude, store.longitude]);
        }
      });
      if (bounds.isValid()) {
        map.fitBounds(bounds);
      } else {
        console.log("Invalid bounds, unable to fit map.");
      }
    }
  }, [storeData]);

  return (
    <div id="map-container">
      <MapContainer
        center={[latitude, longitude]}
        zoom={25}
        ref={mapRef}
        style={{ height: "calc(100vh - 80px)" }}
      >
        <TileLayer
          url="https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}" // Google Streets view
        />

        {storeData &&
          storeData.map((store, idx) => {
            return (
              store.latitude &&
              store.longitude && (
                <Marker
                  key={store.id}
                  position={[store.latitude, store.longitude]}
                  icon={customIcon}
                >
                  <Popup>
                    <div
                      style={{
                        minWidth: "200px",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${
                            store.store_banner
                              ? store.store_banner[0]
                              : placeholder
                          })`,
                          borderRadius: `13px 13px 0 0`,
                          height: "150px",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                      <div className="p-3 d-flex flex-column gap-2">
                        <h2 className="fs-5 fw-bold text-capitalize mb-0">
                          {store.store_name}
                        </h2>
                        <p className="m-0 text-color-light-Black m-0">
                          {store?.store_address} {store?.city} {store?.state}
                        </p>
                        {store.store_contact_number && (
                          <div className="d-flex align-items-center gap-2">
                            <FiPhone className="icon-size-20" />
                            <a
                              href={`tel:+${store?.store_contact_number}`}
                              className="text-color-black"
                            >
                              {" "}
                              <p className=" fw-bold text-decoration-underline m-0 text-color-black font-size-14">
                                {store?.store_contact_number}
                              </p>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </Popup>
                </Marker>
              )
            );
          })}
        {/* Additional map layers or components can be added here */}
      </MapContainer>
    </div>
  );
};

export default Index;
