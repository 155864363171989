import React from 'react'

const index = ({ strokeText, filledText, noLine = false, textCenter = false }) => {
    return (
        <>
            <div className={textCenter ? 'text-center' : 'w-fit-content'}>
                <div className='h-fit-content'>
                    <span className='fs-1 pe-2 text-capitalize m-0'
                        style={{
                            fontFamily: 'Roboto , sans-serif',
                            fontWeight: '1000',
                            letterSpacing: 1,
                            lineHeight: '30px',
                            color: '#204A54'
                        }}
                    >
                        {filledText}
                    </span>
                    <span className='fs-1'
                        style={{
                            fontWeight: '1000',
                            letterSpacing: 1,
                            fontFamily: 'Roboto , sans-serif',
                            WebkitTextStrokeColor: '#204A54',
                            WebkitTextStrokeWidth: '1px',
                            WebkitTextFillColor: 'transparent',
                            WebkitBackgroundClip: 'text',
                            lineHeight: '30px'
                        }}
                    >
                        {strokeText}
                    </span>
                </div>
                {noLine ? "" : <div className='text-end h-fit-content line--below-title'>
                    <span className="me-2" style={{ width: '45%', display: 'inline-block', border: '2.5px solid #1F799D' }}></span>
                    <span className="" style={{ width: '25%', display: 'inline-block', border: '2.5px solid #204A54' }}></span>
                </div>}
            </div>
        </>
    )
}

export default index
