import React from 'react'

const Index = ({ id, question, answer, accordionExample2 }) => {
    return (

        <div class="accordion-item border-0 p-2 p-sm-3 mb-3 ">
            <h2 class="accordion-header" id="headingOne">
                <div className=" border-dashed">
                    <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target={'#' + id} aria-expanded="false" >
                        {question}
                    </button>
                </div>

            </h2>
            <div id={id} class="accordion-collapse collapse" data-bs-parent={accordionExample2 || "#accordionExample"}>
                <div class="accordion-body text-color-light-Black">
                    {answer}
                </div>
            </div>
        </div>
    )
}

export default Index
