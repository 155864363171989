import React from 'react'
import { Route, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom'
import { RoutePaths } from '../RoutesPath/RoutePaths'
import App from '../../App';
import Screens from '../../screens/app'
import ErrorPage from '../../components/error-Page';
const { Home, FAQ, AboutUs, PartnerWithUs, Stores, PrivacyPage, TermsCondition } = Screens
const { home, aboutUs, faq, partnerWithUs, stores, privacy, termsCondition } = RoutePaths
const router = createBrowserRouter([
    {
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: home,
                element: <Home />
            },
            {
                path: faq,
                element: <FAQ />

            },
            // {
            //     path: aboutUs,
            //     element: <AboutUs />
            // },
            {
                path: stores,
                element: <Stores />
            },
            {
                path: partnerWithUs,
                element: <PartnerWithUs />
            },
            {
                path: privacy,
                element: <PrivacyPage />
            },
            {
                path: termsCondition,
                element: <TermsCondition />
            }
        ]
    }
])

const index = () => {
    return (
        <RouterProvider router={router} />
    )
}

export default index
