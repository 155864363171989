import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const Index = () => {
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(null);

  useEffect(() => {
    if (prevPathname === pathname) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    setPrevPathname(pathname);
  }, [pathname, prevPathname]);

  return null;
};

export default Index;