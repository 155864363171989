import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const Index = ({ openModal, handleOpenCloseModal, children, showThankYouPopUp, handleOpenCloseThankyouModal, size }) => {
    return (
        <>
            <Modal
                show={openModal || showThankYouPopUp}
                onHide={handleOpenCloseModal || handleOpenCloseThankyouModal}
                size={size}
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{ padding: '2rem' }}>{children}</Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleOpenCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleOpenCloseModal}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default Index
