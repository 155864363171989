import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BannerSection from '../../../components/common/bannerSection'
import BannerImage from '../../../assests/images/commonBanner.png'
import SectionTitle from '../../../components/common/sectionTitle'
import Input from '../../../components/common/appInput'
import { BsInfoCircle } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import AlertModal from '../../../components/common/alertModal'
import { useSubmitRetailerDataMutation } from '../../../services/contactSlice'
import { toast } from 'react-toastify'

const Index = () => {
  const [retailerData, { data, isLoading, isSuccess: reatailerSuccess, }] = useSubmitRetailerDataMutation()
  const phoneRegex = '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'
  const formik = useFormik({
    initialValues: {
      store_name: "",
      store_address: "",
      contact_person_name: "",
      position: "",
      contact_email: "",
      contact_phone: "",
      operating_years: '',
      num_of_outlets: "",
      featured_brands: ""
    },
    validationSchema:
      Yup.object().shape({
        store_name: Yup.string().required('please enter your store name'),
        store_address: Yup.string().required('please enter your store address'),
        contact_person_name: Yup.string().required('please enter your phone number'),
        position: Yup.string().required('please enter your position'),
        contact_email: Yup.string().email('Email is Invalid').required('please enter your email'),
        contact_phone: Yup.string().matches(phoneRegex, 'phone number is not valid'),
        operating_years: Yup.string().required('please enter number of years'),
        num_of_outlets: Yup.string().required('please enter number of outlets'),
        featured_brands: Yup.string()
      })
    ,


    onSubmit: async (values, { setFieldValue, resetForm }) => {
      try {
        const response = await retailerData(values).unwrap()
        if (response.name === 'Created') {
          resetForm()
          Object.entries(values).forEach(([fieldName, value]) => setFieldValue(fieldName, ''))
          toast.success('Retailer Added Successfully',)
        }

      } catch (error) {
        console.log(error)
      }
    },
  })

  return (
    <>
      <div>
        <BannerSection image={BannerImage} bannerHeading={'Expression of Interest: partnering  with Specxweb'} subHeading={"At Specxweb, we're pioneering a collective approach to elevate the optical retail sector. Our platform links independent eyewear retailers, providing enhanced inventory options, streamlined operations, and collaborative customer engagement strategies. If you're looking to amplify your market presence and drive growth, we welcome you to express your interest in partnering  with us."} />
        <div className="container-fluid bg-color-tertiary">
          <div className="bg-with-hexImage py-5">
            <div className='container'>
              <div className='my-3'>
                <SectionTitle filledText={'Retailer'} strokeText={'Information'} />
              </div>
              <form action="" className="form" onSubmit={formik.handleSubmit}>
                <>
                  {/* retailer Information */}

                  <div id='retailerInformation' className='mb-5'>
                    <div className="row my-4">
                      <div className="col-12 col-sm-12 col-md-4">
                        <Input
                          label={'Store Name'}
                          placeholder={'Enter Name'}
                          name={'store_name'}
                          type={'text'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.store_name}
                          errorText={formik.errors.store_name}
                          touched={formik.touched.store_name}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <Input
                          label={'Store Address'}
                          placeholder={'Enter Address'}
                          name={'store_address'}
                          type={'text'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.store_address}
                          errorText={formik.errors.store_address}
                          touched={formik.touched.store_address}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <Input
                          label={'Contact Person Name'}
                          placeholder={'Enter Name'}
                          name={'contact_person_name'}
                          type={'text'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_person_name}
                          errorText={formik.errors.contact_person_name}
                          touched={formik.touched.contact_person_name}

                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-4">
                        <Input
                          label={'Position/Title'}
                          placeholder={'Enter Title'}
                          name={'position'}
                          type={'text'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.position}
                          errorText={formik.errors.position}
                          touched={formik.touched.position}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <Input
                          label={'Contact Email'}
                          placeholder={'Enter Email Address'}
                          name={'contact_email'}
                          type={'email'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_email}
                          errorText={formik.errors.contact_email}
                          touched={formik.touched.contact_email}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <Input
                          label={'Contact Phone'}
                          placeholder={'Enter Contact Number'}
                          name={'contact_phone'}
                          type={'number'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_phone}
                          errorText={formik.errors.contact_phone}
                          touched={formik.touched.contact_phone}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Operational overview */}
                  <div id='operational Overview'>
                    <div className="py-4">
                      <SectionTitle filledText={'Operational'} strokeText={'Overview'} />
                    </div>
                    <div className="row mb-1 mb-sm-3">
                      <div className="col-12 col-sm-12 col-md-6">
                        <Input
                          label={'Years Operating'}
                          placeholder={'Enter Number Of Year'}
                          name={'operating_years'}
                          type={'number'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.operating_years}
                          errorText={formik.errors.operating_years}
                          touched={formik.touched.operating_years}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-6">
                        <Input
                          label={'Number of Outlets'}
                          placeholder={'Enter Number Of Year'}
                          name={'num_of_outlets'}
                          type={'number'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.num_of_outlets}
                          errorText={formik.errors.num_of_outlets}
                          touched={formik.touched.num_of_outlets}
                        />
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12">
                        <h6 className='fs-6 text-color-black my-2 fw-bold'>Featured Eyewear Brands</h6>
                        <textarea
                          name="featured_brands"
                          rows="5"
                          className='w-100 textarea'
                          placeholder='Enter Featured Brands'
                          value={formik.values.featured_brands}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div class="alert alert-warning alert-dismissible fade show d-flex gap-2 align-items-center" role="alert">
                          <strong><BsInfoCircle className='icon-size-20' /></strong> kindly mention name of the Brands you deals in
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-center my-3'>
                    <button className='rounded-pill bg-color-primary text-color-white submit-btn-padding border-0 fs-5' type='submit'
                      disabled={formik.isSubmitting}> {formik.isSubmitting ? 'submitting' : "Submit"} <BsArrowRightShort /></button>
                  </div>
                  {reatailerSuccess && <h6 className='fs-6 text-color-black my-2 fw-bold text-center ' style={{
                    color: '#097969'
                  }}>Form Has Been Submitted Succesfully</h6>}
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
