import React from 'react'
import tickIcon from '../../../assests/images/Featured icon.svg'
import { Link } from 'react-router-dom'
const Index = ({ showThankYouPopUp, setShowThankYouPopUp }) => {
    return (
        <>
            <div className="text-center">
                <img src={tickIcon} alt="" className='img-fluid' />
                <h3 className='fs-4 fw-bold mt-2'>Thank You</h3>
                <p className='mt-3'>Your feedback is invaluable. We're continuously working to improve your Specxweb experience.</p>
                <Link
                    to={'/'}
                    onClick={() => setShowThankYouPopUp(!showThankYouPopUp)}
                >
                    <button className='rounded-pill mt-3 bg-color-primary text-color-white px-5 py-2 border-0 fs-6'>Back To home</button>
                </Link>
            </div>
        </>
    )
}

export default Index
