import React from 'react'
import BannerSection from '../../../components/common/bannerSection'
import BannerImage from '../../../assests/images/commonBanner.png'
import ImageSection from '../../../components/common/imageSection'
import OutlineButton from '../../../components/common/outlineButton'
import SectionTitle from '../../../components/common/sectionTitle'
import aboutDummy from '../../../assests/images/aboutus2.png'
import mission from '../../../assests/images/ourMission.png'
const index = () => {
  return (
    <div>
      <BannerSection image={BannerImage} bannerHeading={'About Us'} subHeading={'Feel free to customize and expand upon these questions and answers to better suit your specific FAQ needs.'} />
      <div className='container mx-auto' id="about-us">
        <div className="py-5 ">
          <div className="row align-items-center gx-sm-5 gy-4" >
            <div className="col-12 col-sm-12 col-md-6">
              <ImageSection image={aboutDummy} />
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <SectionTitle strokeText={"Us"} filledText={'About'} />
              <p className='fs-6 fw-normal font-size-18 mt-3'>
                At Specxweb, we're forging a new pathway in the eyewear industry by unifying independent retailers on a groundbreaking platform designed to revolutionize inventory management and customer engagement. Specxweb is working with the aim of leveling the playing field for small and medium optical retailers, providing a lifeline against the tide of market consolidation and the competitive pressure from larger chains.
              </p>
              {/* <OutlineButton title={"Read More"} navigationLink={aboutUs} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className='border-bottom border-1 ' />
      <div className="container">
        <div className="py-5 ">
          <div className="row align-items-center gx-0 gx-sm-5" >
            <div className="col-12 col-sm-12 col-md-6">
              <SectionTitle strokeText={"Vision & Mission"} filledText={'Our'} />
              <p className='fs-5 fw-normal my-3' style={{ lineHeight: '35px' }}>
                To be the leading collaborative platform in the optical
                industry, where every retailer, regardless of size, can
                compete on an equal footing, and customers can enjoy
                unparalleled choice and service.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <ImageSection image={mission} left={'40px'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default index
