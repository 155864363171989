import { specxApiSlice } from "../rtk/base-query"

const storeMapApiEnhancedSlice = specxApiSlice.enhanceEndpoints({})

export const storeMapSlice = storeMapApiEnhancedSlice.injectEndpoints({
    endpoints: (builder) => ({
        storeData: builder.query({
            query: (params) => ({
                url: '/website/store-list',
                method: 'GET',
                params
            }),
        })

    })
})

export const { useStoreDataQuery } = storeMapSlice