import { Outlet } from 'react-router-dom';
import './App.scss';
import Header from './components/header'
import Footer from './components/footer'
import Topbar from './components/Top-bar'
import ScrollTop from './components/common/scrollTop'
import { Provider } from 'react-redux';
import { store } from './reduxStore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
      <Provider store={store}>
        <ToastContainer/>
          <ScrollTop />
          <Topbar />
          <Header />
          <div style={{ minHeight: 'calc(100vh - 130px)' }}>
            <Outlet />
          </div>
          <Footer />
      </Provider>
    </>
  );
}

export default App;
