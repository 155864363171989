import React from 'react'
import BannerSection from '../../../components/common/bannerSection'
import BannerImage from '../../../assests/images/commonBanner.png'
import FaqBar from '../../../components/common/faqBar'
const index = () => {
    return (
        <div>
            <BannerSection image={BannerImage} bannerHeading={'FAQ'} subHeading={'Feel free to customize and expand upon these questions and answers to better suit your specific FAQ needs.'} />
            {/* FAQ section starts */}
            <div className="container-fluid bg-color-tertiary">
                <div className="bg-with-hexImage py-5">
                    <div className="container">
                        <div className="row mb-3 gy-3">
                            <div className="col-12 col-sm-12 col-md-6 ">
                                <div class="accordion" id="accordionExample">
                                    <FaqBar id='collapseOne' question={" What Does 'POLARIZED' Mean ?"} answer={' Polarized lenses fight glare by minimizing the passage of undesirable light to the eye. The result is sharper vision, more vivid color and greater depth perception.'} />
                                    <FaqBar id='collapseTwo' question={"HOW CAN POLARIZED SUNGLASSES IMPROVE YOUR VISION?"} answer={'The reduction in glare offered by polarized sunglasses has many positive effects. It not only provides protection from exposure to bright light, it can also enhance visibility, contrast, clarity and depth perception.'} />
                                    <FaqBar id='collapseThree' question={"ARE POLARIZED LENSES 100% UV PROTECTED?"} answer={"Not always. Polarizing and UV protection are two different things. A top-quality pair of sunglasses will always deliver a high-degree of UV protection, whereas a polarized len's main role is to reduce glare. However, in all cases, it is best to choose polarized lenses that also offer 100% UV protection."} />
                                    <FaqBar id='collapseFour' question={"WHAT ARE ANTI-REFLECTIVE LENSES?"} answer={'Anti-reflective lenses reduce distracting reflections that can bounce off the back of the lens into the eye.'} />
                                    <FaqBar id='collapseFive' question={"WHAT ARE THE BENEFITS OF GRADIENT LENSES?"} answer={"Gradient lenses fade from a darker tint to a lighter tint, allowing for comfortable vision over a wide range of distance and lighting conditions."} />
                                    <FaqBar id='collapseSix' question={"WHAT DOES 'PHOTOCHROMATIC' MEAN?"} answer={'Photochromatic lenses darken with UV exposure, adjusting to a variety of light conditions.'} />
                                    <FaqBar id='collapseSeven' question={"WHAT ARE THE BENEFITS OF MIRRORED LENSES?"} answer={"Mirrored lenses have reflective coating that reduces the amount of light passing through to the eye."} />
                                    <FaqBar id='collapseEight' question={"WHAT ARE TRANISITIONS LENSES?"} answer={'Transitions lenses automatically adapt to changing light conditions and help protect against harmful blue light indoors and outdoors. They are hassle-free lenses that make everyday life simpler. You can pick your favorite color and choose your style for a look you love. Always ask our eye care professionals for Transitions lenses.'} />
                                    <FaqBar id='collapseNine' question={"WHAT ARE PROGRESSIVE LENSES ?"} answer={"Progressive lenses have different viewing zones so that adjustments can be made for both near and far vision. To move from looking at a distance to reading, move your eyes down the lens rather than lowering your head. Clarity might be slightly different in different areas of the lens, especially in the periphery. You might experience some distortion on the sides of the lenses. To look at something to the side, slightly turn your head to make sure you use the central portion of the lens. Play with these movements to learn how to maximize clarity for different uses."} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <div class="accordion" id="accordionExample2">
                                    <FaqBar accordionExample2={'#accordionExample2'} id='collapseTen' question={"HOW CAN I AVOID DAMAGING MY GLASSES ?"} answer={'Avoid exposing lenses to hairspray, abrasive cleaners, household (window) spray cleaners, chemicals, ammonias, or bleaches. You should also avoid placing your glasses in any area of excessive heat such as car dashboards, home radiators or stove tops, since lens coatings may deteriorate and damage the frame or loosen frame alignment. To help prevent scratches, do not lay your glasses face down. Always keep your eyeglasses in a clean case when not in use.'} />
                                    <FaqBar accordionExample2={'#accordionExample2'} id='collapseEleven' question={"WHAT ARE THE BEST WAYS TO CLEAN MY GLASSES ?"} answer={"Using a mild liquid detergent, rinse thoroughly with warm, not hot, water or use an Crystal Clear eyewear cleaner. Dry your lenses with a clean, soft cloth made from cotton, terry or microfiber. If your lenses aren’t too dusty, use the supplied cleaning cloth to remove smudges and fingerprints without liquid. In general, avoid wiping lenses dry if water is unavailable. Wash the cloth periodically. Do not use paper products of any kind to clean lenses to avoid scratches and damage to your lenses."} />
                                    <FaqBar accordionExample2={'#accordionExample2'} id='collapseTwelve' question={"IS ULTRA-VOILET (UV) PROTECTION IMPORTANT ?"} answer={"UV protection on your eyewear is very important. Just like sunscreen for your skin, UV protection will help protect your eyes from harmful rays, which can cause damage to your eyes and the skin around your eyes. Crystal Clear Eyewear carries a wide range of UV-protected and polarized lenses so you can be sure you're getting the durability and protection you need to help safeguard your eyes."} />
                                    <FaqBar accordionExample2={'#accordionExample2'} id='collapseThirteen' question={"CAN I SLEEP IN MY CONTACT LENSES ?"} answer={"Some contact lenses are designed for night wear, but not all. Be sure to consult your eye doctor for specific instructions based on your individual vision needs and contact lenses."} />
                                    <FaqBar accordionExample2={'#accordionExample2'} id='collapseFourteen' question={"WHAT ARE CATARACTS ?"} answer={"Cataracts occur when the clear lens inside your eye becomes cloudy or misty over time, as you age. The early stages of a cataract do not necessarily affect your sight, but the only proven treatment for a cataract is surgery."} />
                                    <FaqBar accordionExample2={'#accordionExample2'} id='collapseFifteen' question={"WHY FACE SHAPE IS IMPORTANT ?"} answer={"Understanding your face shape can help you find the best frames to flatter your look. For example, round frames tend to look best on square faces because they help soften the features, while rectangular frames can help a round face appear longer and slimmer. If you're not sure which eyeglasses fit your face shape, visit our store for recommendations hand-picked for you."} />
                                    <FaqBar accordionExample2={'#accordionExample2'} id='collapseSixteen' question={"WHAT IS BIFOCAL LENS ?"} answer={"A bifocal lens provides two focal points, one for near and one for distance. These may have a distinct line for showing the location of the bifocal."} />
                                    <FaqBar accordionExample2={'#accordionExample2'} id='collapseSeventeen' question={"WHAT IS PD ?, HOW DO I MEASURE A PD ?"} answer={"PD stands for pupillary distance- which is the space between the centers of your pupils. This number tells the lens maker where to put the optical center for your glasses. Measuring your PD can be tricky, visit our store for perfect measurement."} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index
