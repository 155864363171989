import React, { useState } from 'react'

const Index = ({ image, left = '-20px' }) => {
   
    return (
        <>
            <div className='position-relative common-image-section'>
                <div className='position-absolute border border-1 br-color-secondary bg-color-light-blue img-movable-back' style={{
                    width: '90%',
                    height: '100%',
                    zIndex: '-1',
                    left: left,
                    top: '-10px'
                }}></div>
                <div>
                    <img src={image} alt="" className='img-fluid' />
                </div>
            </div>
        </>
    )
}

export default Index
