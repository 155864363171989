import React, { useState } from "react";
import { FiPhone } from "react-icons/fi";
import placeholder from "../../../assests/images/placeholderImages.png";
import MapComponent from '../../../components/MapComponent'
import { useStoreDataQuery } from "../../../services/storeMapSlice";
import { globalConstant } from "../../../constant/globalConstant";
import InfiniteScroll from "react-infinite-scroll-component";
const Index = () => {
  const [queryParams, setQueryParams] = useState(globalConstant.initialParams)
  const { data: storeResponse, isLoading } = useStoreDataQuery(queryParams)
  const storeData = storeResponse?.data || []

  const fetchMoreData = () => {
    if (storeResponse?.totalPages > queryParams.page) {
      setQueryParams((prev) => ({ ...prev, limit: prev.limit + 10 }))
    }
  };
  const handleMouseHover = (store)=>{

  }
  return (
    <div>
      <div className="container py-5" id="store-maps">
        <div className="row position-relative">
          <div className="col-12 col-sm-12 col-md-6">
            <h4 className="fs-4 my-3">
              {storeResponse && storeResponse.count} Total Stores
            </h4>

            <InfiniteScroll
              dataLength={storeData?.length || 0}
              next={fetchMoreData}
              loader={<h4>Loading...</h4>}
              hasMore={
                storeResponse?.totalPages !== queryParams.page
              }>
              {!isLoading && storeData &&
                storeData.map((store, indx) => {
                  return (
                    <div className="store-card  border-2 border mb-3" key={store.id} onMouseEnter={()=>handleMouseHover(store)}>
                      <div className="row">
                        <div
                          className="col-12 col-sm-6 col-md-4 col-lg-4 store-card-img"
                          style={{
                            background: `url(${store?.store_banner ? store?.store_banner[0] : placeholder})`,
                          }}
                        ></div>
                        <div className="col-12 col-sm-6 col-md-12 col-lg-8 store-card-description">
                          <div className="p-3 ">
                            <div>
                              <h6 className="m-0 store-heading fw-bold">
                                {store?.store_name}
                              </h6>
                              <p className="my-2 text-color-light-Black font-size-14">
                                {store?.store_address} {store?.city} {store?.state}
                              </p>
                              <p className="text-color-light-Black my-2 font-size-14">
                                <span className="fw-bold"> Hours:</span>{" "}
                                {store?.opening_time} to {store?.closing_time}
                              </p>
                              <div className="row my-3 gy-2 gy-sm-0">
                                <div className="col-12 col-sm-12 col-md-6">
                                  {store.store_contact_number && (
                                    <div className="d-flex align-items-center gap-2">
                                      <FiPhone className="icon-size-20" />
                                      <a
                                        href={`tel:+${store?.store_contact_number}`}
                                        className="text-color-black"
                                      >
                                        {" "}
                                        <p className=" fw-bold text-decoration-underline m-0 text-color-black font-size-14">
                                          {store?.store_contact_number}
                                        </p>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </InfiniteScroll>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <div
              className="position-sticky"
              style={{
                height: "100vh",
                top: "80px",
              }}
            >
              <MapComponent storeData={storeData ?? storeData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
