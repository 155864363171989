import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import sparkling from '../../../assests/images/Sparkling.png'
import OutlineButton from '../../../components/common/outlineButton'
import CustomerCard from '../../../components/common/customerCard'
import bannerImg from '../../../assests/images/bannerImg.png'
import bannerMouseIcon from '../../../assests/images/clarity_mouse-line.png'
import icon from '../../../assests/images/Icon.png'
import Primaryicon from '../../../assests/images/primaryColorIcon.png'
import { RoutePaths } from '../../../routes/RoutesPath/RoutePaths'
import { BsArrowRightShort } from "react-icons/bs";
import '../../../assests/css/home.scss'
import ImageSection from '../../../components/common/imageSection'
import SectionTitle from '../../../components/common/sectionTitle'
import aboutDummy from '../../../assests/images/about-collage.jpg'
import mission from '../../../assests/images/ourMission.png'
import customer1 from '../../../assests/images/cutomerImg1.png'
import customer2 from '../../../assests/images/customerImg2.png'
import customer3 from '../../../assests/images/customerCard3.png'
import retail2 from '../../../assests/images/retailpartner2.png'
import retail1 from '../../../assests/images/reatailpartner1.png'
import retail3 from '../../../assests/images/retailer3.png'
import retail4 from '../../../assests/images/retailer4.png'
import retail5 from '../../../assests/images/communityCollaboration.jpeg'
import AlertModal from '../../../components/common/alertModal'
import FeedbackForm from '../../../components/common/feedbackForm'
import ThankYou from '../../../components/common/thankYou'

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
const Index = () => {
    const { home, faq, partnerWithUs, aboutUs } = RoutePaths
    const [openModal, setOpenModal] = useState(false)
    const [showThankYouPopUp, setShowThankYouPopUp] = useState(false)
    const handleOpenCloseThankyouModal = () => {
        setShowThankYouPopUp(!showThankYouPopUp)
    }
    const handleOpenCloseModal = () => {
        setOpenModal(!openModal)
    }
    return (
        <div>
            <div className='container mx-auto' id='home-section'>
                <div className='py-5 position-relative'>
                    <div className='row align-items-center'>
                        <div className='col-12 col-sm-12 col-md-6 mb-3 order-2 order-md-1'>
                            {/* <div className='border border-1 rounded-pill w-fit-content  px-3 py-1 mb-3'>
                                <div className='d-flex gap-2 align-items-center'>
                                    <img src={sparkling} alt='sparkling images' />
                                    <p className='fs-5 m-0 text-color-primary text-capitalize' >Eyes on the Future: SpecXWeb Awaits</p>
                                </div>
                            </div> */}
                            <div>
                                <h1 className="fs-1 fw-bold text-color-primary text-capitalize mb-3 home-title">Empowering Retailers, Enriching Choices</h1>
                                <p className='font-size-18 w-75'>At Specxweb, we revolutionizes optical retail with a unique platform, blending extensive inventories and competitive pricing. It benefits both retailers and end customers, offering a broader selection, better prices, and a unified loyalty program, ensuring local retailers can compete with large chains while enhancing the shopping experience for consumers.</p>
                                {/* <OutlineButton title={"let's connect"} navigationLink={partnerWithUs} /> */}
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 text-center order-1 order-md-2'>
                            <div className='position-relative'>
                                <img src={bannerImg} alt="Banner Image" className='img-fluid' />
                                <div className='position-absolute bannerImg-icon'>
                                    <img src={icon} alt="sparkling icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='position-absolute' style={{ bottom: '-30px', right: '50%' }} >
                        <img src={bannerMouseIcon} alt="" />
                    </div> */}
                </div>
            </div>
            <div className='border-bottom border-1 ' />
            <div className='container mx-auto' id="about-us">
                <div className="py-5 ">
                    <div className="row align-items-center gx-sm-5 gy-4" >
                        <div className="col-12 col-sm-12 col-md-6">
                            <ImageSection image={aboutDummy} />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <SectionTitle strokeText={"Us"} filledText={'About'} />
                            <p className='fs-6 fw-normal font-size-18 mt-3'>
                                At Specxweb, we're forging a new pathway in the eyewear industry by unifying independent retailers on a groundbreaking platform designed to revolutionize inventory management and customer engagement. Specxweb is working with the aim of leveling the playing field for small and medium optical retailers, providing a lifeline against the tide of market consolidation and the competitive pressure from larger chains.
                            </p>
                            {/* <OutlineButton title={"Read More"} navigationLink={aboutUs} /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='border-bottom border-1 ' />
            <div className="container">
                <div className="py-5 ">
                    <div className="row align-items-center gx-0 gx-sm-5" >
                        <div className="col-12 col-sm-12 col-md-6 order-sm-0 order-2 mt-3">
                            <SectionTitle strokeText={"Vision & Mission"} filledText={'Our'} />
                            <p className='fs-5 fw-normal my-3' style={{ lineHeight: '35px' }}>
                                To be the leading collaborative platform in the optical
                                industry, where every retailer, regardless of size, can
                                compete on an equal footing, and customers can enjoy
                                unparalleled choice and service.
                            </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <ImageSection image={mission} left={'40px'} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='border-bottom border-1 ' />
            <div className="container">
                <div className="py-5 ">
                    <div>
                        <SectionTitle filledText={'For Our'} strokeText={'Customer'} />
                        <p className='fs-6 fw-normal mt-3 w-75'  >A World of Choices Awaits
                            Specxweb brings the future of eyewear shopping to your neighborhood retail store. We're here to enrich your shopping experience with an unparalleled selection, personalized service, and the convenience of digital technology combined with the warmth of local retail.
                        </p>
                    </div>
                    <div className="position-relative">
                        <Carousel
                            responsive={responsive}
                            showDots={true}
                            infinite={true}
                            // autoPlay={true}
                            renderDotsOutside={true}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="carousel-container"
                            itemClass="carousel-item-padding-40-px">
                            <div className='mx-3'>
                                <CustomerCard id={1} image={customer1} headingText={"Vast Selection"} descriptionText={"Access an extensive array of eyewear from the comfort of your home or the convenience of your local store."} />
                            </div>
                            <div className='mx-3'>
                                <CustomerCard id={2} image={customer2} headingText={"Unmatched Convenience"} descriptionText={"Enjoy a seamless shopping journey, from browsing and virtual try-ons to offline expert consultations and personalized after-sales support."} />
                            </div>
                            <div className='mx-3'>
                                <CustomerCard id={3} image={customer3} headingText={"Personalized Experiences"} descriptionText={"ABenefit from tailored recommendations and a unified loyalty program, enhancing your shopping experience at every step."} />
                            </div>
                        </Carousel>
                    </div>
                    {/* <div className="row align-items-center gx-sm-5 my-5 floating-section" >
                        <div className="col col-sm-12 col-md-6 col-lg-4 child-section">
                            <CustomerCard id={1} image={customer1} headingText={"Vast Selection"} descriptionText={"Access an extensive array of eyewear from the comfort of your home or the convenience of your local store."} />
                        </div>
                        <div className="col col-sm-12 col-md-6 col-lg-4 child-section position-relative" style={{ top: '-30px' }}>
                            <CustomerCard id={2} image={customer2} headingText={"Unmatched Convenience"} descriptionText={"Enjoy a seamless shopping journey, from browsing and virtual try-ons to offline expert consultations and personalized after-sales support."} />
                        </div>
                        <div className="col col-sm-12 col-md-6 col-lg-4 child-section position-relative" style={{ top: '-60px' }}>
                            <CustomerCard id={3} image={customer3} headingText={"Personalized Experiences"} descriptionText={"ABenefit from tailored recommendations and a unified loyalty program, enhancing your shopping experience at every step."} />
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='border-bottom border-1 ' />
            <div className="container">
                <div className="py-5 ">
                    <div>
                        <SectionTitle filledText={'For Our'} strokeText={'Retail Partners'} />
                        <p className='fs-6 fw-normal mt-3 retailer-section-description' style={{ width: "65%" }} >Specxweb empowers retailers by providing a platform to extend their reach, enhance their service offerings, and compete effectively in a digital-first world.
                        </p>
                    </div>
                    <div className="position-relative">

                        <Carousel
                            responsive={responsive}
                            showDots={true}
                            infinite={true}
                            // autoPlay={true}
                            renderDotsOutside={true}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="carousel-container"
                            itemClass="carousel-item-padding-40-px">
                            <div className='mx-2'>
                                <CustomerCard id={4} image={retail2} headingText={"Expand Your Inventory"} descriptionText={"Tap into a shared inventory system that allows you to offer a wider range of products without additional investment."} />
                            </div>
                            <div className='mx-2'>
                                <CustomerCard id={5} image={retail1} headingText={"Strengthen Your Market Position"} descriptionText={"Leverage collective insights and marketing efforts to attract more customers and boost sales."} />
                            </div>
                            <div className='mx-2'>
                                <CustomerCard id={6} image={retail3} headingText={"Elevate Customer Satisfaction"} descriptionText={"Utilize our platform's advanced tools to offer personalized services and a streamlined shopping experience, building loyalty and trust."} />
                            </div>
                            <div className='mx-2'>
                                <CustomerCard id={7} image={retail4} headingText={"Centralized Loyalty and Service Standards"} descriptionText={"Join a network where loyalty programs and service standards are unified, ensuring customer satisfaction and repeat business."} />
                            </div>
                            <div className='mx-2'>
                                <CustomerCard id={8} image={retail5} headingText={"Community and Collaboration"} descriptionText={"Be part of a supportive community where knowledge, resources, and opportunities are shared for the benefit of all."} />
                            </div>
                        </Carousel>
                    </div>

                </div>
            </div>
            <div className="bg-color-tertiary">
                <div className="container  mt-4">
                    <div className="py-5 ">
                        <div className='text-center'>
                            <img src={Primaryicon} alt="" className='img-fluid mb-2 primary-icon' />
                            <div className='mt-3'>
                                <SectionTitle filledText={'Join'} strokeText={'Us'} noLine={true} textCenter={true} />
                            </div>
                            <p className='font-size-14  fw-normal mt-3 text-center mx-auto w-75' >Step into the future of optical retail with Specxweb. Together, we can transform challenges into opportunities and competition into collaboration. Be part of a network that values growth, innovation, and the success of every member
                            </p>
                            <p className="fs-6 fw-bold text-color-primary">
                                Dear Retailers, Let's Connect, Collaborate, Conquer – With Specxweb, your retail journey is just beginning.
                            </p>
                            <Link to={partnerWithUs}><button className='py-2 rounded-pill bg-color-primary text-color-white px-5 border-0'
                                onClick={handleOpenCloseModal}

                            >
                                Let's Connect <BsArrowRightShort /></button></Link>
                        </div>
                    </div>
                </div>
            </div>
            {
                openModal && <AlertModal
                    openModal={openModal}
                    handleOpenCloseModal={handleOpenCloseModal}
                    size='lg'
                >
                    <FeedbackForm
                        handleOpenCloseModal={handleOpenCloseModal}
                        showThankYouPopUp={showThankYouPopUp}
                        setShowThankYouPopUp={setShowThankYouPopUp}
                        setOpenModal={setOpenModal}
                        openModal={openModal}
                    />
                </AlertModal>
            }
            {
                showThankYouPopUp &&
                <AlertModal
                    showThankYouPopUp={showThankYouPopUp}
                    handleOpenCloseThankyouModal={handleOpenCloseThankyouModal}
                    size='md'
                >
                    <ThankYou
                        showThankYouPopUp={showThankYouPopUp}
                        setShowThankYouPopUp={setShowThankYouPopUp}
                    />

                </AlertModal>
            }
        </div >
    )
}

export default Index
