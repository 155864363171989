import React from 'react'

const Index = ({
    label,
    placeholder,
    name,
    type,
    value,
    onChange = () => { },
    onBlur = () => { },
    errorText,
    touched

}) => {
    return (
        <div>
            <h6 className='fs-6 text-color-black my-2 fw-bold '>{label}</h6>
            <div className='common-bordered-input rounded-2 p-1 p-sm-2' style={{
                borderColor: errorText ? "#ffbfbf " : '#D9D9D9 '
            }}>
                <input type={type} class="form-control"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                >
                </input>
            </div>
            {touched && (errorText ? (<span style={{ color: '#ff0000' }}>{errorText}</span>) : "")}
        </div>
    )
}

export default Index
