import React from 'react'

const Index = () => {
    return (
        <>
            <div className='container'>
                <div className='py-md-3 py-2'>
                    <h5 className='fs-4 fw-bold text-color-primary'> Terms & Conditions</h5>
                    <h5 className='fs- fw-bold text-color-primary'>  Introduction: </h5>

                    <p className='fs-6 fw-normal font-size-18 mt-3'> Welcome to the Specxweb website, operated by MA Eyewear Private Limited. These terms and conditions govern your use of our website and services. By accessing or using our website, you agree to be bound by these terms. If you do not agree to these terms, please do not use our website. </p>

                    <h5 className='fs- fw-bold text-color-primary'>  Use of the Website: </h5>


                    <p className='fs-6 fw-normal font-size-18 mt-3'> You may use our website only for lawful purposes and in accordance with these terms.
                        You must not use our website:
                        In any way that breaches any applicable local, national, or international law or regulation.
                        In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.
                        To send, knowingly receive, upload, download, use, or re-use any material which does not comply with our content standards.
                    </p>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>  We use this information to fulfill your orders, improve our services, communicate with you, and for security purposes.</p>

                    <h5 className='fs- fw-bold text-color-primary'> Accounts and Registration: </h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'> To access certain features of our website, you may be required to register and create an account.
                        You must provide accurate and complete information during the registration process.
                        You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                    <h5 className='fs- fw-bold text-color-primary'>   Intellectual Property Rights:</h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>All intellectual property rights in the website and its content are owned by us or our licensors. You may only use the content as expressly authorized by these terms or with our prior written permission.
                        You must not copy, reproduce, modify, create derivative works from, distribute, or publicly display any content from our website without our prior written permission and the appropriate third party, as applicable.</p>

                    <h5 className='fs- fw-bold text-color-primary'> User Content: </h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>You may be able to upload or submit content to our website. You retain all rights in, and are solely responsible for, the content you post.
                        You grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>
                </div>
            </div >
        </>
    )
}

export default Index
