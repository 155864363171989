import React from 'react'
import Home from './Home'
import FAQ from './FAQ'
import PartnerWithUs from './PartnerWithUs'
import AboutUs from './AboutUs'
import Stores from './Stores'
import PrivacyPage from './PrivacyPage'
import TermsCondition from './TermsCondition'
const Screens = {
    Home, FAQ, PartnerWithUs, AboutUs, Stores, PrivacyPage, TermsCondition

}
export default Screens
