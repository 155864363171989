import React from 'react'

const Index = () => {
    return (
        <>
            <div className='container'>
                <div className='py-md-3 py-2'>
                    <h5 className='fs-4 fw-bold text-color-primary'> Privacy Policy </h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'> Welcome to Specxweb, a brand operated by MA Eyewear Private Limited. Your privacy is paramount to us, and we are committed to safeguarding your personal data. This Privacy Policy outlines how we collect, use, process, and share your information through our website and services. By accessing or using Specxweb, you consent to the practices described in this policy. </p>

                    <h5 className='fs- fw-bold text-color-primary'> Information Collection and Use: </h5>


                    <p className='fs-6 fw-normal font-size-18 mt-3'>   We collect information you provide directly to us, such as when you create an account, engage in a transaction, or communicate with us. </p> This may include: <br />

                    <span className="fs-6 fw-bold">Personal details: </span>
                    Name, contact information, and other identifiers. <br />
                    <span className="fs-6 fw-bold"> Transactional data: </span>
                    Purchases, inquiries, and customer service interactions. <br />
                    <span className="fs-6 fw-bold"> Digital footprints:</span>
                    IP addresses, device information, and usage statistics via cookies. <br />

                    <p className='fs-6 fw-normal font-size-18 mt-3'>  We use this information to fulfill your orders, improve our services, communicate with you, and for security purposes.</p>

                    <h5 className='fs- fw-bold text-color-primary'> WhatsApp Communication: </h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>  Specxweb utilizes WhatsApp for direct communication with our clients. By opting into our services, you agree to receive updates, notifications, and promotional messages via WhatsApp. You can modify your communication preferences by contacting our customer service team.</p>

                    <h5 className='fs- fw-bold text-color-primary'>    Cookies and Automatic Data Collection:</h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>Our website uses cookies to enhance your user experience. You can manage cookie preferences in your browser settings, though this may affect certain functionalities of our site.</p>

                    <h5 className='fs- fw-bold text-color-primary'> Data Sharing and Disclosure: </h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>We may share your information with third-party service providers for order fulfillment, payment processing, and marketing assistance. We ensure these parties adhere to strict data protection standards.</p>

                    <h5 className='fs- fw-bold text-color-primary'>  Security Measures: </h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>  Specxweb employs robust security measures to protect your data, including encryption and access controls. While we strive to secure your information, we cannot guarantee absolute security. </p>

                    <h5 className='fs- fw-bold text-color-primary'>  Your Rights: </h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>You have the right to access, correct, or delete your personal data. Additionally, you may opt out of promotional communications at any time.</p>

                    <h5 className='fs- fw-bold text-color-primary'>  Changes to This Policy: </h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>  Our Privacy Policy may evolve over time. We will notify you of any significant changes and update the policy date accordingly.</p>

                    <h5 className='fs- fw-bold text-color-primary'>  Contact Us: </h5>
                    <p className='fs-6 fw-normal font-size-18 mt-3'>For questions or concerns about our privacy practices, please contact us at <a
                        className='fw-bold link-color' href="mailto:info@specxweb.com" >info@specxweb.com</a></p>
                    <p className='fs-6 fw-normal font-size-18 mt-3'> This Privacy Policy is effective as of 4 April 2024 and supersedes any previous versions.</p>
                </div>
            </div >
        </>
    )
}

export default Index
