import { specxApiSlice } from '../rtk/base-query';

const contactApiEnhancedSlice = specxApiSlice.enhanceEndpoints({});

export const contactSlice = contactApiEnhancedSlice.injectEndpoints({
    endpoints: (builder) => ({
        submitFeedbackData: builder.mutation({
            query: (data) => {
                return ({
                    url: 'admin/feedback-form',
                    method: 'POST',
                    body: data
                })
            },
        }),
        submitRetailerData: builder.mutation({
            query: (data) => {
                return ({
                    url: 'admin/retailer-form',
                    method: "POST",
                    body: data
                })
            }
        })
    })
})

export const { useSubmitFeedbackDataMutation, useSubmitRetailerDataMutation , } = contactSlice