import React from 'react'
import { VscMail } from "react-icons/vsc";
import { FiPhone } from "react-icons/fi";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { BsFacebook } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import Callicon from '../../assests/svg/call-icon.svg'
import mailicon from '../../assests/svg/mail-icon.svg'
const index = () => {
    return (
        <>
            <div id='top-bar' className='top-bar-background '>
                <div className='container py-2 mx-auto'>
                    <div className='d-flex align-items-center justify-content-between '>
                        <div className='row justify-content-center align-items-center '>
                            <div className=' col-6 d-flex justify-content-center align-items-center gap-2 pe-3 border-1 border-end'>
                                <div className=''>
                                    <img src={mailicon} alt="" style={{ width: '35px', }} />
                                </div>
                                <div className='text-color-white'>
                                    <a href="mailto:info@specxweb.com" className='text-decoration-none text-color-white'><p className='m-0 fs-6 fw-normal'>info@specxweb.com</p></a>
                                </div>
                            </div>
                            <div className=' col-6 d-flex justify-content-center align-items-center gap-2'>
                                <div className=''>
                                    <img src={Callicon} alt="" style={{ width: '35px', }} />
                                </div>
                                <div className='text-color-white'>
                                    <a href="tel:+91-731-7968482" className='text-decoration-none text-color-white'> <p className='m-0 fs-6 fw-normal w-max-content '>+91-731-7968482</p></a>
                                </div>
                            </div>
                        </div>
                        <div className='d-none d-sm-flex d-flex align-items-center gap-3'>
                            <a href="https://www.instagram.com/invites/contact/?i=1ewn4qtkyydfx&utm_content=twe8n6u" target='_blank'><BiLogoInstagramAlt className='text-color-white icon-size-20' style={{ fontSize: '30px' }} /></a>
                            <a href="https://www.facebook.com/share/ymS1LnwD4aXqceCV/?mibextid=qi2Omg" target='_blank'> <BsFacebook className='text-color-white icon-size-20' style={{ fontSize: '24px' }} /></a>
                            <a href="https://youtube.com/@specxweb?si=wSwSBoI7E2ZJbqYG" target='_blank' >  <BsYoutube className='text-color-white icon-size-20' style={{ fontSize: '25px' }} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index
