import React, { useState } from 'react'
import logo from '../../assests/svg/logo.svg'
import { Link, NavLink } from 'react-router-dom'
import { BsArrowRightShort } from "react-icons/bs";
import { Navbar, Nav, Button } from 'react-bootstrap';
import { RoutePaths } from '../../routes/RoutesPath/RoutePaths'
import AlertModal from '../../components/common/alertModal'
import FeedbackForm from '../common/feedbackForm'
import ThankYou from '../../components/common/thankYou'
const Index = () => {
  const { home, faq, aboutUs, partnerWithUs, stores } = RoutePaths
  const [openModal, setOpenModal] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [showThankYouPopUp, setShowThankYouPopUp] = useState(false)
  const handleOpenCloseThankyouModal = () => {
    setShowThankYouPopUp(!showThankYouPopUp)
  }
  const handleOpenCloseModal = () => {
    setOpenModal(!openModal)
  }

  return (
    <>
      <Navbar
        expand="lg"
        variant="light"
        className="border-bottom border-1 position-sticky bg-color-white top-0"
        style={{ zIndex: 1001 }}
        expanded={expanded}
      >
        <div className="container">
          <Navbar.Brand as={Link} to={home} className="p-2 ps-0">
            <img src={logo} alt="specX logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => { setExpanded(!expanded) }} />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="gap-3 gap-md-5 align-items-center">
              <Nav.Item onClick={() => { setExpanded(false) }}>
                <NavLink
                  to={home}
                  className={({ isActive, isPending }) =>
                    isPending ? 'pending' : isActive ? 'navlink-active' : ''
                  }
                >
                  Home
                </NavLink>
              </Nav.Item>
              {/* <Nav.Item onClick={() => { setExpanded(false) }}>
                <NavLink
                  to={aboutUs}
                  className={({ isActive, isPending }) =>
                    isPending ? 'pending' : isActive ? 'navlink-active' : ''
                  }
                >
                  About
                </NavLink>
              </Nav.Item> */}
              <Nav.Item onClick={() => { setExpanded(false) }}>
                <NavLink
                  to={partnerWithUs}
                  className={({ isActive, isPending }) =>
                    isPending ? 'pending' : isActive ? 'navlink-active' : ''
                  }
                >
                  Partner With Us
                </NavLink>
              </Nav.Item>
              <Nav.Item onClick={() => { setExpanded(false) }}>
                <NavLink
                  to={stores}
                  className={({ isActive, isPending }) =>
                    isPending ? 'pending' : isActive ? 'navlink-active' : ''
                  }
                >
                  Stores
                </NavLink>
              </Nav.Item>
              <Nav.Item onClick={() => { setExpanded(false) }}>
                <NavLink
                  to={faq}
                  className={({ isActive, isPending }) =>
                    isPending ? 'pending' : isActive ? 'navlink-active' : ''
                  }
                >
                  FAQ
                </NavLink>
              </Nav.Item>
              <Nav.Item onClick={() => { setExpanded(false) }}>
                <Button variant="navbar" className="btn-navbar rounded-pill" onClick={handleOpenCloseModal}>
                  Feedback <BsArrowRightShort />
                </Button>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      {openModal && (
        <AlertModal openModal={openModal} handleOpenCloseModal={handleOpenCloseModal} size="lg">
          <FeedbackForm
            handleOpenCloseModal={handleOpenCloseModal}
            showThankYouPopUp={showThankYouPopUp}
            setShowThankYouPopUp={setShowThankYouPopUp}
            setOpenModal={setOpenModal}
            openModal={openModal}
          />
        </AlertModal>
      )}

      {showThankYouPopUp && (
        <AlertModal openModal={showThankYouPopUp} handleOpenCloseModal={handleOpenCloseThankyouModal} size="md">
          <ThankYou showThankYouPopUp={showThankYouPopUp} setShowThankYouPopUp={setShowThankYouPopUp} />
        </AlertModal>
      )}
    </>
  )
}
export default Index
