import React from 'react'
import commonBanner from '../../../assests/images/commonBanner.png'
const Index = ({ image, bannerHeading, subHeading }) => {
  return (
    <>
      <div className='w-100 banner-bg-overlay d-flex align-items-center justify-content-center' style={{
        background: `url(${image})`,
        backgroundPosition: "center",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className='position-relative py-5 text-center px-2 border-box'>
          <h1 className='text-color-white display-6 fw-bold'>{bannerHeading}</h1>
          <p className='text-color-white w-75 text-center mx-auto fs-6'>{subHeading}
          </p>
        </div>
      </div>
    </>
  )
}

export default Index
