import { configureStore } from '@reduxjs/toolkit'
import { contactSlice } from "../services/contactSlice"
import { specxApiSlice } from '../rtk/base-query';
import { storeMiddleware } from '../rtk/middleware';

const rtkMiddleware = [specxApiSlice.middleware];

export const store = configureStore({
    reducer: {
        [contactSlice.reducerPath]: contactSlice.reducer
        // [specxApiSlice.reducerPath]: specxApiSlice.reducer,

    },
    middleware: (getDefaultMiddleware) =>
        storeMiddleware(getDefaultMiddleware())
        // getDefaultMiddleware().concat(rtkMiddleware.middleware),
})